.chat-bubble-editor-wrapper {
    width: auto;
}

.chat-bubble-edit-panel-wrapper {
    display: flex;
    flex-direction: column;
    width: 400px;
    background-color: white;
    box-shadow: var(--primary-shadow);
    padding: var(--primary-padding);
    border-radius: var(--primary-border-radius);
}

.chat-bubble-menu-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.chat-bubble-menu-link {
    background-color: var(--secundary-bg);
    text-decoration: none;
    color: black;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 1000px;
    width: 20%;
    cursor: pointer;
}

.chat-bubble-menu-link.active {
    background-color: var(--primary-clr);
    color: white;
}

.chat-bubble-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
}

.chat-bubble-action-button {
    width: calc(50% - 5px);
}

.chat-bubble-action-button.true {
    background-color: var(--secundary-bg);
}

.chat-bubble-action-button.true:hover {
    transform: translateY(0);
}

.chat-bubble-edit-properties-wrapper {
    width: 100%;
}

.chat-bubble-edit-property-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.356);
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.property-title {
    font-weight: 500;
    font-size: 20px;
    margin: 0;
}

.property-input {
    outline: 2px solid black;
    background-color: var(--super-soft-bg);
    border-radius: var(--secundary-border-radius);
    height: 50px;
    padding: 0 15px;
    font-weight: 700;
    margin: 0;
    width: 100px;
    overflow: hidden;
}

.property-input[type="color"] {
    border: 0;
    padding: 0;
    width: 200%;
    height: 200%;
    cursor: pointer;
    transform: translate(-25%, -25%)
}

textarea.property-input {
    width: calc(100% - 30px);
    padding-top: 3px;
    height: 60px;
    resize: none;
}

.chat-bubble-preview {
    margin-right: 50px;
    width: 700px;
    height: 700px;
    border: dashed 2px rgb(156, 156, 156);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.preview {
    position: absolute;
    top: -30px;
    left: 5px;
}
body {
    font-family: 'Gilroy';
}


/* Basic Template Classes */
.form-wrapper {
    background-color: var(--primary-bg);
    padding: var(--primary-padding);
    border-radius: var(--primary-border-radius);
    box-shadow: var(--primary-shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

input {
    border: none;
    background-color: var(--super-soft-bg);
    border-radius: var(--secundary-border-radius);
    height: 50px;
    padding: 0 15px;
    font-weight: 700;
    margin-bottom: 30px;
    width: calc(100% - 4px - 30px);
}

input:focus {
    outline: var(--select-border)
}

form {
    width: 100%;
}

button {
    border: none;
    padding: var(--primary-button-padding);
    border-radius: var(--secundary-border-radius);
    font-weight: bold;
    transition: all 100ms ease-in-out;
}

button:hover {
    transform: translateY(-5px);
}

.primary-button {
    background-color: var(--primary-clr);
    color: white;
}

.secundary-button {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid black;
    padding: var(--secundary-button-padding);
}

h2 {
    font-size: var(--title-font-size);
    margin-bottom: 15px;
}

/* Authentication */
.auth-page-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-form-wrapper {
    width: 300px;
}

.sign-in-with-google {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--secundary-bg);
    border-radius: var(--secundary-border-radius);
    width: 100%;
    height: 60px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
}

.sign-in-with-google:hover {
    transform: translateY(-5px);
}

.sign-in-with-google h2 {
    font-size: 20px;
    margin: 0;
}

.sign-in-with-google img {
    width: 30px;
    margin: 0 10px 0 20px;
}


/* SideMenu */
.side-menu {
    width: 500px;
    height: calc(100vh);
    position: sticky;
    top: 0;
    left: 0;
    padding: var(--primary-padding);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.logo {
    font-weight: 700;
    margin-bottom: 50px;
    font-size: 40px;
}

.menu-sideline {
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.561);
    opacity: 0.4;
    /* margin-left: 30px; */
    /* transform: translateY(-25px) */
}

.menu-collections-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-link-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 45px - 15px);
    background-color: none;
    border-radius: var(--secundary-border-radius);
    height: 40px;
    padding: 12px 10px 12px 35px;
    cursor: pointer;
    margin-bottom: 5px;
}

.menu-link-wrapper:hover {
    background-color: var(--super-soft-bg);
}

.menu-link-wrapper.active {
    background-color: var(--super-soft-bg);
}

.menu-link-wrapper.active {
    color: var(--primary-clr);
}

.menu-link-text {
    font-weight: 600;
    font-size: 20px;
    margin-left: 10px;
}

.menu-link-icon {
    font-size: 30px;
}

/* GENERAL */
.page-wrapper {
    display: flex;
    /* flex-direction: row; */
}

.application-wrapper {
    width: 100%;
    padding: var(--primary-padding);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.application-wrapper.horizontal {
    flex-direction: row;
}
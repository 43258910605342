:root {
    /* Colors */
    --primary-bg: white;
    --secundary-bg: #E0E0E0;
    --super-soft-bg: #f8f8f8;

    --primary-clr: #FC2220;
    --secundary-clr: #2D2D2D;

    --error-bg: #E1E900;
    --success-bg: #77DD77;
    --warning-bg: #FC2220;

    --error-clr: #A0A601; 
    --success-clr: #2F9C00;
    --warning-clr: #805C00;

    --muted: #eeeeee;


    /* Spacing and Outlines*/
    --primary-padding: 25px;
    --primary-button-padding: 15px 30px;
    --secundary-button-padding: 12px 27px;

    --primary-border-radius: 25px;
    --secundary-border-radius: 15px;
    --select-border: 2px solid var(--primary-clr);

    --primary-shadow: 0px 3px 99px rgba(0, 0, 0, 0.164);


    /* Fonts */
    --title-font-size: 35px;
}